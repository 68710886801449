import styled from "styled-components";

export const ContactStyles = styled.div`
    margin: 4rem 0;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        margin: 2rem 0;
    }

    @media screen and (max-width: 480px) {
        margin: 1rem 0;
    }

    .row {
        justify-content: center;

        .left-container {

            @media screen and (max-width: 767px) {
                margin-bottom: 3rem;
            }

            img {
                margin-top: -2.5rem;
                width: 100%;
                height: 100%;
                object-fit: contain;

                @media screen and (max-width: 767px) {
                    margin-top: 0;
                }
            }
        }

        .right-container {

            .enquiry-elem-wrap {
                margin-bottom: 1rem;

                .label {
                    font-size: .9rem;
                    font-style: italic;
                    display: block;
                    margin-bottom: .1rem;

                    @media screen and (max-width: 480px) {
                        font-size: .8rem;
                    }
                }

                .input-field {
                    font-size: .9rem;
                    font-weight: 400;
                    line-height: 1.3rem;
                    width: 100%;
                    padding: 0 .5rem;
                    height: 2.5rem;
                    outline: none;
                    border: 1px solid var(--dark-color);

                    @media screen and (max-width: 480px) {
                        font-size: .8rem;
                        height: 2rem;
                    }
                }

                .message-field {
                    height: 7rem;
                    padding-top: .5rem;

                    @media screen and (max-width: 480px) {
                        padding-top: .2rem;
                    }
                }
            }

            .button-wrap {
                margin-top: 2rem;
                display: flex;

                @media screen and (max-width: 480px) {
                    margin-top: 1rem;
                }

                button {
                    margin-right: 2rem;
                    outline: none;
                    background-color: var(--primary-color);
                    border: none;
                    padding: .7rem 2rem;
                    color: var(--light-color);

                    @media screen and (max-width: 767px) {
                        padding: .5rem 1.5rem;
                    }
                }
            }
        }
    }
`;