import styled from "styled-components";

export const HomeStyles = styled.div`
    overflow: hidden;

    .row{
        margin-bottom: 8rem;

        @media screen and (max-width: 991px) {
            margin-bottom: 6rem;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 5rem;
        }

        @media screen and (max-width: 480px) {
            margin-bottom: 4rem;
        }

        .content-container {
            text-align: left;

            .price-wrap {
                font-size: .8rem;
                margin-top: 2rem;
                margin-bottom: 3rem;

                @media screen and (max-width: 480px) {
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }


                .rupee-icon {
                    font-size: 1.1rem;
                    margin-top: -.4rem;
                    margin-left: .3rem;

                    @media screen and (max-width: 480px) {
                        font-size: .8rem;
                    }

                }

                span {
                    font-size: 2rem;
                    font-weight: 600;

                    @media screen and (max-width: 480px) {
                        font-size: 1.5rem;
                    }

                }
            }
        }

        .left-content {
            text-align: right;
        }

        .image-container {

            @media screen and (max-width: 767px) {
                margin-bottom: 1.5rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .desc-wrap {
        text-align: left;

        .desc-heading {
            font-weight: 600;
        }
    }

    .tab-wrap {
        text-align: left;

        .tab-title-wrap {
            padding: 1rem;
            margin-bottom: .3rem;
            background-color: var(--light-red-color);
            cursor: pointer;

            span {
                font-weight: 600;
                margin-right: .8rem;
            }

            .down-icon {
                font-size: .9rem;
            }

            .rotated {
                color: var(--primary-color);
                rotate: 180deg;
            }
        }

        .row {
            visibility: hidden;
            height: 0;
            margin: 0;

            .tab-content-wrap {

            }

            .tab-image-wrap {

                @media screen and (max-width: 767px) {
                    margin-bottom: 1.5rem;
                }

                @media screen and (max-width: 480px) {
                    margin-bottom: 1rem;
                }


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .active {
            padding: 1rem;
            visibility: visible;
            height: auto;

            @media screen and (max-width: 480px) {
                padding: .6rem 0;
            }

        }
    }

    .gallery-container {

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .thumbnail-big {
            max-width: 600px;
            max-height: 625px;

            .row {
                margin-bottom: 0;

                .thumbnail-small {
                    max-width: 300px;
                    max-height: 300px;
                }
            }
        }

        .mobile-invisible {

            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }

    .btn-wrap {
        margin-top: -4rem;

        @media screen and (max-width: 767px) {
            margin-top: -2rem;
        }

    }

    .testimonials-container {
        position: relative;
        padding-top: 4rem;
        height: 350px;

        @media screen and (max-width: 991px) {
            position: static !important;
            padding-top: 1rem;
            height: auto;
            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 480px) {
            padding-top: 1rem;
        }


        .testimonial-wrap {
            position: absolute;
            width: 430px;

            @media screen and (max-width: 1199px) {
                width: 380px;
            }

            @media screen and (max-width: 991px) {
                position: static !important;
                width: auto;
                margin-bottom: 1.5rem;
            }

            @media screen and (max-width: 480px) {
                margin-bottom: 1.5rem;
            }


            .client-review-wrap {
                padding: 1.5rem 2rem 3rem 2rem;
                background-color: var(--light-red-color);

                @media screen and (max-width: 1199px) {
                    padding: 1rem 1rem 3rem 1rem;
                }

                .icon {
                    margin-bottom: 1rem;

                    img {
                        width: 4rem;
                        height: auto;
                        opacity: .6;

                        @media screen and (max-width: 991px) {
                            width: 3rem;
                        }

                        @media screen and (max-width: 480px) {
                            width: 2rem;
                        }
                    }
                }

                .review {
                    margin-bottom: 0;
                }
            }

            .active {
                background-color: var(--primary-color);
                
                .review {
                    color: var(--light-color);
                }
            }

            .client-wrap {

                img {
                    max-width: 80px;
                    height: auto;
                    margin-top: -2.5rem;
                }

                h5 {
                    font-size: .9rem;
                    font-weight: 400;
                    margin-top: .5rem;
                }
            }
        }

        .left {
            left: 0;
        }

        .right {
            right: 0;
        }

        .active-wrap {
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2.5rem;
            z-index: 1;

            @media screen and (max-width: 991px) {
                margin-top: 0;
            }
        }
    }
`;

export const HeroStyle = styled.div`

    @media screen and (max-width: 991px) {}

    @media screen and (max-width: 767px) {}

    @media screen and (max-width: 480px) {}

    .hero-inner-container {

        .hero-left-container {

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .hero-right-container {
            padding: 7rem 6rem 5rem 4rem;
            background-color: var(--dark-color);
            position: relative;

            @media screen and (max-width: 1199px) {
                padding: 5rem 5rem 5rem 3rem;
            }

            @media screen and (max-width: 991px) {
                padding: 3rem 3rem 4rem 2rem;
            }



            img {
                width: 50%;
                height: auto;
                position: absolute;
                right: 0;
                top: -5px;
                opacity: 0.2;
            }

            .bold-text {
                font-size: 3rem;
                line-height: 4.5rem;
                font-weight: 600;
                color: var(--light-color);

                @media screen and (max-width: 1199px) {
                    font-size: 2.5rem;
                    line-height: 3.8rem;
                }

                @media screen and (max-width: 991px) {
                    font-size: 2rem;
                    line-height: 3.3rem;
                }

                @media screen and (max-width: 480px) {
                    font-size: 1.8rem;
                    line-height: 3rem;
                }




                span{
                    color: var(--primary-color);
                }
            }

            .btn-wrap {
                margin-top: 4rem;

                @media screen and (max-width: 991px) {
                    margin-top: 3rem;
                }

            }
        }
    }
`;