import styled from "styled-components";

export const GalleryStyles = styled.div`
  text-align: center;
  margin: 4rem 0;

  @media screen and (max-width: 992px) {
    margin: 2rem 0;
  }

  .page-title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.4rem;
    font-style: italic;
    margin-bottom: 2rem;

    @media screen and (max-width: 992px) {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    span {
      border: 2px dotted var(--primary-color);
      padding: 0 1rem;
    }
  }

  .filter-btn-wrap {
    margin-top: 4rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
      margin-top: 2rem;
    }

    .btn-menu-tab {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.4rem;
      padding: 0.5rem 1.5rem;
      background-color: rgba(211, 211, 211, 0.4);

      @media screen and (max-width: 992px) {
        font-weight: 400;
        padding: 0.4rem 1.2rem;
      }

      @media screen and (max-width: 767px) {
        padding: 0.4rem 1rem;
        font-size: 0.9rem;
      }

      @media screen and (max-width: 480px) {
        padding: 0.4rem 0.7rem;
        font-size: 0.8rem;
      }

      span {
        cursor: pointer;
      }
    }

    .active-tab {
      font-style: italic;
      background-color: rgba(221, 42, 27, 1);
      color: var(--light-color);
    }
  }

  .slider-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .btn-prev {
      font-size: 2.5rem;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;

      @media screen and (max-width: 480px) {
        font-size: 2rem;
        left: 30px;
      }
    }

    .btn-next {
      font-size: 2.5rem;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;

      @media screen and (max-width: 480px) {
        font-size: 2rem;
        right: 30px;
      }
    }

    .btn-close {
      top: 60px;
      right: 40px;
      font-size: 3rem;

      @media screen and (max-width: 480px) {
        font-size: 2rem;
        right: 30px;
      }
    }

    .btn-prev,
    .btn-next,
    .btn-close {
      position: fixed;
      cursor: pointer;
      opacity: 0.6;
      color: #fff;
      z-index: 9999;

      &:hover {
        opacity: 1;
      }

      @media screen and (max-width: 480px) {
        opacity: 1;
      }
    }

    .full-screen-image {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  .image-thumbnail-container {
    .image-thumbnail {
      max-height: 220px;
      margin-bottom: 1rem;
      cursor: pointer;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        max-height: 200px;
      }

      @media screen and (max-width: 480px) {
        max-height: 180px;
      }

      figure {
        background: var(--primary-color);
        width: 100%;
        height: 100%;

        &:hover img {
          opacity: 0.4;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 1;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
        }
      }
    }

    .paginationBttns {
      width: 100%;
      height: 40px;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

      @media screen and (max-width: 991px) {
        margin-top: 1.5rem;
      }

      @media screen and (max-width: 767px) {
        margin-top: 1rem;
      }

      @media screen and (max-width: 480px) {
        margin-top: 0.5rem;
      }
    }

    .paginationBttns a {
      padding: 5px 10px;
      margin: 8px;
      cursor: pointer;
      text-decoration: none;
      font-size: 0.9rem;

      &:hover {
        color: #fff;
        background-color: rgba(221, 42, 27, 1);
      }

      @media screen and (max-width: 991px) {
        margin: 6px;
      }

      @media screen and (max-width: 767px) {
        margin: 4px;
      }

      @media screen and (max-width: 480px) {
        margin: 1px;
      }
    }

    .previousBttns,
    .nextBttns {
      color: #000;

      &:hover {
        color: rgba(221, 42, 27, 1) !important;
        background-color: transparent !important;
      }
    }

    .paginationDisabled {
      opacity: 0.2;

      &:hover a {
        cursor: default;

        &:hover {
          color: #000 !important;
        }
      }
    }

    .paginationActive a {
      color: #fff;
      background-color: rgba(221, 42, 27, 1);
    }
  }
`;
