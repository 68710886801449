import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { GalleryStyles } from "../styles/Gallery.styles";
import ImagesData from "../components/ImagesData";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { MainContainer } from "../GlobalStyles";
import ReactPaginate from "react-paginate";

const Gallery = () => {

  const ReverseImageData = ImagesData.slice(0).reverse()

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState(ReverseImageData);
  const [toggleClass, setToggleClass] = useState(0);
  const [isScreenXs, setIsScreenXs] = useState(false);
  const [isScreenSm, setIsScreenSm] = useState(false);
  const [isScreenMd, setIsScreenMd] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const imagePerPage = isScreenXs ? 10 : isScreenSm ? 18 : isScreenMd ? 24 : 30;
  const pageVisited = pageNumber * imagePerPage;
  const pageCount = Math.ceil(items.length / imagePerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleResize = () => {
    window.innerWidth < 576 ? setIsScreenXs(true) : setIsScreenXs(false);
    window.innerWidth > 575 && window.innerWidth < 768
      ? setIsScreenSm(true)
      : setIsScreenSm(false);
    window.innerWidth > 768 && window.innerWidth < 992
      ? setIsScreenMd(true)
      : setIsScreenMd(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const itemsPerPage = items.slice(pageVisited, pageVisited + imagePerPage);

  const displayImages = itemsPerPage.map((slide, id) => {
    return (
      <div
        className="image-thumbnail col-lg-2 col-md-3 col-sm-4 col-6"
        key={id}
        onClick={() => handleOpenModal(id)}
      >
        <figure>
          <img src={slide.src} alt={slide.alt} />
        </figure>
      </div>
    );
  });

  const filterItem = (categItem, index) => {
    const updatedItems = ReverseImageData.filter((currElem) => {
      return currElem.category === categItem;
    });

    setToggleClass(index);
    setItems(updatedItems);
    setPageNumber(0);
  };

  const handleOpenModal = (idx) => {
    setSlideNumber(idx);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(itemsPerPage.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  const nextSlide = () => {
    slideNumber + 1 === itemsPerPage.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <GalleryStyles>
      <Helmet>
        <title>Mehandi Studio : Photo Gallery</title>
        <link
          rel="canonical"
          href="https://www.mehandistudio.com/gallery"
        />
        <meta
          name="descriptions"
          content="Mehandi Studio, Best Mehandi Studio in Delhi, Best Henna Artists, Best Mehandi Designers, Best Henna Designers"
        />
      </Helmet>
      <h1 className="page-title">
        <span>Some Mehandi Designs What We Captured in Camera</span>
      </h1>
      <div className="filter-btn-wrap">
        <div
          className={
            toggleClass === 0 ? "btn-menu-tab active-tab" : "btn-menu-tab"
          }
          onClick={() => {
            setItems(ReverseImageData);
            setToggleClass(0);
          }}
        >
          <span>All</span>
        </div>
        <div
          className={
            toggleClass === 1 ? "btn-menu-tab active-tab" : "btn-menu-tab"
          }
          onClick={() => filterItem("bridal", 1)}
        >
          <span>Bridal</span>
        </div>
        <div
          className={
            toggleClass === 2 ? "btn-menu-tab active-tab" : "btn-menu-tab"
          }
          onClick={() => filterItem("regular", 2)}
        >
          <span>Regular</span>
        </div>
        <div
          className={
            toggleClass === 3 ? "btn-menu-tab active-tab" : "btn-menu-tab"
          }
          onClick={() => filterItem("engagement", 3)}
        >
          <span>Engagement</span>
        </div>
        <div
          className={
            toggleClass === 4 ? "btn-menu-tab active-tab" : "btn-menu-tab"
          }
          onClick={() => filterItem("3d", 4)}
        >
          <span>3D</span>
        </div>
      </div>
      {openModal && (
        <div className="slider-wrap">
          <FaChevronCircleLeft className="btn-prev" onClick={prevSlide} />
          <FaChevronCircleRight className="btn-next" onClick={nextSlide} />
          <IoCloseSharp className="btn-close" onClick={handleCloseModal} />
          <div className="full-screen-image">
            <img
              src={itemsPerPage[slideNumber].src}
              alt="vinay mehandi artist"
            />
          </div>
        </div>
      )}
      <MainContainer style={{ marginTop: "2rem" }}>
        <div className="image-thumbnail-container row gx-3">
          {displayImages}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttns"}
            nextLinkClassName={"nextBttns"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </MainContainer>
    </GalleryStyles>
  );
};

export default Gallery;
