import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { HeroStyle, HomeStyles } from "../styles/Home.style";
import PrimaryButton from "../components/Buttons";
import {
  Head,
  MainContainer,
  SectionSubtitle,
  SectionTitle,
} from "../GlobalStyles";
import { FaRupeeSign, FaChevronDown } from "react-icons/fa";
import VideoScroller from "../components/VideoScroller";

const Home = () => {
  const [toggleIcon, setToggleIcon] = useState(1);

  const [toggleState, setToggleState] = useState(1);

  const clickHandler = (e) => {
    setToggleState(e);
    setToggleIcon(e);
  };

  return (
    <HomeStyles>
      <HeroStyle>
        <div className="row g-0 hero-inner-container">
          <div className="hero-left-container col-md-5 col-sm-6">
            <img
              src="../../images/vinay-mehandi-artist-hero-image.jpg"
              alt="vinay mehandi artist"
            />
          </div>
          <div className="hero-right-container col-md-7 col-sm-6">
            <img
              className="background-image"
              src="../../images/background-artwork.png"
              alt="vector"
            />
            <h1 className="bold-text">
              With 10<span>+</span> Years of Experience as a Mehandi Artist and
              handled 10000<span>+</span> work Assignments...
            </h1>
            <div className="btn-wrap">
              <PrimaryButton title="Contact Me" link="/contact" />
            </div>
          </div>
        </div>
      </HeroStyle>
      <MainContainer>
        <div className="row">
          <div className="content-container col-md-6">
            <Head>Journey of Being a Professional Mehandi Artist in Delhi</Head>
            <p>
              In my school days I had lot of interest in drawing. Gradually I
              got attracted towards Mehandi Art. And soon I had a grip on
              Mehandi and I started applying Mehandi for my friends and family
              members. I got lot of appreciation from all of them which made me
              to pursue my further career as Mehandi Artist.
            </p>
            <p>
              Word of mouth publicity helped me to built a huge clientele in
              India and abroad. With 10+ years of experience in this traditional
              and creative art, I have lots of students also who come to learn
              Mehandi from me.
            </p>
          </div>
          <div className="image-container col-md-6">
            <img
              src="../../images/vinay-mehandi-artist.jpg"
              alt="vinay mehandi artist"
            />
          </div>
        </div>
      </MainContainer>
      <MainContainer>
        <SectionSubtitle>Types of Mehandi Designs I Provide</SectionSubtitle>
        <SectionTitle>DESIGN EXPERTIES</SectionTitle>
        <div className="desc-wrap">
          <p className="desc-heading">
            How many types of mehandi designs are actually present in the field
            of Mehandi Art?
          </p>
          <p>
            Mehandi is the traditional art of painting the hands, feet or body
            with a paste made from the powdered, dried leaves of the henna
            plant. It stains a usually cherry-red to brown color but this can
            vary with time left on and a range of other factors. Coffee, lemon,
            tea and essential oils are usually added for more texture, smell or
            to help get better Mehandi Stains .
          </p>
          <p>
            Mehandi is undoubtedly an important part of Indian festivities.
            Women of all ages, be it elderlies, middle-aged ones or even kids,
            everyone loves to love their hands with pretty and detailed Mehandi
            designs.
          </p>
          <p>There are many types of Mehandi designs. Some of them are :</p>
        </div>
        <div className="tab-wrap">
          <div className="tab-title-wrap" onClick={() => clickHandler(1)}>
            <span>Bridal Mehandi Design</span>
            <FaChevronDown
              className={toggleIcon === 1 ? "down-icon rotated" : "down-icon"}
            />
          </div>
          <div
            className={
              toggleState === 1
                ? "row active flex-column-reverse flex-md-row"
                : "row flex-column-reverse flex-md-row"
            }
          >
            <div className="tab-content-wrap col-md-6">
              Bridal Mehandi designs are elaborate and reduced at the same time.
              The detailing is close to finesse and every element of the design
              holds great meaning. These designs are popular during traditional
              celebrations like Diwali, Karva chauth and especially in weddings
              for bride. Birds, animals, sun, kalash, bride and groom figures
              are featured quite often in Indian wedding Mehandi designs.
              <br />
              <br />
              Bridal Mehandi designs are elaborate and reduced at the same time.
              The detailing is close to finesse and every element of the design
              holds great meaning. These designs are popular during traditional
              celebrations like Diwali, Karva chauth and especially in weddings
              for bride.
            </div>
            <div className="tab-image-wrap col-md-6">
              <img
                src="../../images/vinay-mehandi-artist-bridal-design.jpg"
                alt="vinay mehandi artist bridal design"
              />
            </div>
          </div>
          <div className="tab-title-wrap" onClick={() => clickHandler(2)}>
            <span>Rajasthani Mehandi Design</span>
            <FaChevronDown
              className={toggleIcon === 2 ? "down-icon rotated" : "down-icon"}
            />
          </div>
          <div
            className={
              toggleState === 2
                ? "row active flex-column-reverse flex-md-row"
                : "row flex-column-reverse flex-md-row"
            }
          >
            <div className="tab-content-wrap col-md-6">
              Rajasthani mehandi designs are elaborate and most of the designs
              cover all hands and feet, the kind of henna art that covers every
              inch of your hands and legs. When it comes to the artistic part of
              these designs, they top the henna mehandi designs! The art of
              these mehandi designs is unbeatable. Patterns that are fine and
              neat combined with highly difficult portraits of a king, queen,
              elephant, hindu gods and peacocks form the Rajasthani mehandi
              designs.
              <br />
              <br />
              Rajasthani mehandi designs are not only a favorite among Marwari
              bride’s but also a favorite among Bollywood celebrities !
            </div>
            <div className="tab-image-wrap col-md-6">
              <img
                src="../../images/Rajasthani-mehandi-designs.jpg"
                alt="vinay mehandi artist bridal design"
              />
            </div>
          </div>
          <div className="tab-title-wrap" onClick={() => clickHandler(3)}>
            <span>Madhubani Mehandi Design</span>
            <FaChevronDown
              className={toggleIcon === 3 ? "down-icon rotated" : "down-icon"}
            />
          </div>
          <div
            className={
              toggleState === 3
                ? "row active flex-column-reverse flex-md-row"
                : "row flex-column-reverse flex-md-row"
            }
          >
            <div className="tab-content-wrap col-md-6">
              Madhubani Mehandi Designs is famous for its unique art since
              ancient times. Madhubani painting is famous and popular all over
              the world. They use their paintings on many things and make their
              art famous in the world. Talk about art and Madhubani's name may
              not come in it, maybe? Mehandi is an art of skin decoration.
              Madhubani is also standing in the top range in this competition as
              always.
              <br />
              <br />
              Madhubani mehandi design is one of the most popular mehandi
              designs which are used in most of the functions. The bride and
              groom are also fond of pasting these designs at their wedding as
              these designs make their wedding memorable. You can apply this
              mehandi design to any festival or function as it is suitable for
              everyone.
            </div>
            <div className="tab-image-wrap col-md-6">
              <img
                src="../../images/madhubani-design.jpg"
                alt="vinay mehandi artist"
              />
            </div>
          </div>
          <div className="tab-title-wrap" onClick={() => clickHandler(4)}>
            <span>Engagement Mehandi Design</span>
            <FaChevronDown
              className={toggleIcon === 4 ? "down-icon rotated" : "down-icon"}
            />
          </div>
          <div
            className={
              toggleState === 4
                ? "row active flex-column-reverse flex-md-row"
                : "row flex-column-reverse flex-md-row"
            }
          >
            <div className="tab-content-wrap col-md-6">
              The vital and mandatory aspect of any Indian engagement ceremony
              is applying the mehandi design for engagement. The custom of
              applying mehandi is well known in Asian countries like India,
              Bangladesh, Pakistan, Malaysia and Turkey, who follow this
              beautiful tradition. The patterns are intricate and can vary from
              traditional designs to abstract patterns.
              <br />
              <br />
              The spectacular mehandi designs are applied not only on the palms
              but are also drawn on the upper arms, calves and feet. These
              design patterns can either be drawn thick, decorated with pearls
              or even shaded according to the designs.
            </div>
            <div className="tab-image-wrap col-md-6">
              <img
                src="../../images/engagement.jpg"
                alt="vinay mehandi artist"
              />
            </div>
          </div>
          <div className="tab-title-wrap" onClick={() => clickHandler(5)}>
            <span>Arabic Mehandi Design</span>
            <FaChevronDown
              className={toggleIcon === 5 ? "down-icon rotated" : "down-icon"}
            />
          </div>
          <div
            className={
              toggleState === 5
                ? "row active flex-column-reverse flex-md-row"
                : "row flex-column-reverse flex-md-row"
            }
          >
            <div className="tab-content-wrap col-md-6">
              This beautiful type of mehandi is traditionally done in jet black
              colour. And what makes it stand apart is that there is a lot of
              different shades within a pattern owing to the techniques used.
              The bold patterns and shading seen in a classic as well as new and
              latest Arabic mehandi design are surely different from what you
              see in mehandi designs and makes this style stand apart.
              <br />
              <br />
              The attraction of the latest Arabic mehandi design is that they
              are more on the minimalist side and a lot less trouble. It is
              perfect, for instance, for the small functions the bride wants to
              attend, without feeling like she has overdone it and also keeping
              her hands red.
            </div>
            <div className="tab-image-wrap col-md-6">
              <img src="../../images/arabic.jpg" alt="vinay mehandi artist" />
            </div>
          </div>
          <div className="tab-title-wrap" onClick={() => clickHandler(6)}>
            <span>Glitter Mehandi Design</span>
            <FaChevronDown
              className={toggleIcon === 6 ? "down-icon rotated" : "down-icon"}
            />
          </div>
          <div
            className={
              toggleState === 6
                ? "row active flex-column-reverse flex-md-row"
                : "row flex-column-reverse flex-md-row"
            }
          >
            <div className="tab-content-wrap col-md-6">
              Glitter mehandi designs are becoming very popular these days.
              Adding sparkle colours to the intricate brown mehandi designs
              makes the hand look more attractive. The glitter catches one’s eye
              more easily than the plain brown hue of henna.
              <br />
              <br />
              Glitter mehendi designs are generally elaborate. They demand
              attention and not in a subtle, elegant way. Designs are almost the
              same as the usual mehandi and tattoo patterns. The addition of
              glitter brands the tattoo’s image in mind.
            </div>
            <div className="tab-image-wrap col-md-6">
              <img src="../../images/glitter.jpg" alt="vinay mehandi artist" />
            </div>
          </div>
        </div>
      </MainContainer>
      <MainContainer>
        <VideoScroller />
      </MainContainer>
      <MainContainer>
        <SectionSubtitle>See What I charge for One Person</SectionSubtitle>
        <SectionTitle>PRICING</SectionTitle>
        <div className="row">
          <div className="image-container col-md-6">
            <img
              src="../../images/vinay-mehandi-artist-bridal.jpg"
              alt="vinay mehandi artist bridal pricing"
            />
          </div>
          <div className="content-container col-md-6">
            <Head>Bridal Mehandi Design</Head>
            <hr style={{ marginBottom: ".8rem" }} />
            <p>
              A woman adorns her hands with mehandi a number of times in her
              life, but the bridal mehendi is done only once. Brides are
              experimenting with their henna a lot nowadays. One of the cutest
              ways they have been doing that is by personalizing their mehandi
              design. Adding bride-groom motifs, their long-distance
              relationship story, favorite food & wedding hashtags, they are
              doing it all!
            </p>
            <div className="price-wrap">
              Starts with
              <FaRupeeSign className="rupee-icon" />
              <span>5,100*</span>
            </div>
            <PrimaryButton title="Hire Us" link="/contact" />
          </div>
        </div>
        <div className="row flex-column-reverse flex-md-row">
          <div className="content-container left-content col-md-6">
            <Head>Regular Mehandi Design</Head>
            <hr style={{ marginBottom: ".8rem" }} />
            <p>
              Mehandi has always been an integral part of Indian festivities.
              Historically it was used to provide relief from hot summer days
              and also to ward off evil spirits. Even today various designs of
              mehendi are drawn on hands and feet due to their auspiciousness.
              From weddings to Diwali to Eid, these occasions feel incomplete
              without mehandi painted hands. Often an easy mehandi design which
              goes well with the function is the best choice.
            </p>
            <div className="price-wrap">
              Starts with
              <FaRupeeSign className="rupee-icon" />
              <span>1,100*</span>
            </div>
            <PrimaryButton title="Hire Us" link="/contact" />
          </div>
          <div className="image-container col-md-6">
            <img
              src="../../images/vinay-mehandi-artist-regular.jpg"
              alt="vinay mehandi artist normal pricing"
            />
          </div>
        </div>
      </MainContainer>
      <MainContainer>
        <SectionSubtitle>Some Mehandi Designs What We Captured</SectionSubtitle>
        <SectionTitle>WORK I’VE DONE</SectionTitle>
        <div className="gallery-container">
          <div className="row justify-content-center g-4">
            <div className="thumbnail-big col-md-6">
              <div className="row g-4">
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail1.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail2.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail3.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail4.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
              </div>
            </div>
            <div className="thumbnail-big col-md-6">
              <img
                src="../../images/thumbnail9.jpeg"
                alt="vinay mehandi artist"
              />
            </div>
            <div className="thumbnail-big mobile-invisible col-md-6">
              <img
                src="../../images/thumbnail10.jpeg"
                alt="vinay mehandi artist"
              />
            </div>
            <div className="thumbnail-big col-md-6">
              <div className="row g-4">
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail5.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail6.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail7.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
                <div className="thumbnail-small col-6">
                  <img
                    src="../../images/thumbnail8.jpeg"
                    alt="vinay mehandi artist"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-wrap">
          <PrimaryButton title="View Gallery" link="/gallery" />
        </div>
      </MainContainer>
      <MainContainer>
        <SectionSubtitle>See What My Clients Say About Me</SectionSubtitle>
        <SectionTitle>TESTIMONIALS</SectionTitle>
        <div className="testimonials-container">
          <div className="testimonial-wrap left">
            <div className="client-review-wrap">
              <div className="icon">
                <img
                  src="../../images/quotation-mark-dark.svg"
                  alt="vinay mehandi artist"
                />
              </div>
              <p className="review">
                Great job done by Vinay and it's team. He is a wonderful soul
                and such Simple in nature. Fully professional work and conduct.
              </p>
            </div>
            <div className="client-wrap">
              <img src="../../images/client_1.png" alt="vinay mehandi artist" />
              <h5>Sheetal</h5>
            </div>
          </div>
          <div className="testimonial-wrap active-wrap">
            <div className="client-review-wrap active">
              <div className="icon">
                <img
                  src="../../images/quotation-mark.svg"
                  alt="vinay mehandi artist"
                />
              </div>
              <p className="review">
                He is Extremely professional, punctual, organized, and fun to
                work with everyone👌
              </p>
            </div>
            <div className="client-wrap">
              <img
                src="../../images/client-active.png"
                alt="vinay mehandi artist"
              />
              <h5>Sonam</h5>
            </div>
          </div>
          <div className="testimonial-wrap right">
            <div className="client-review-wrap">
              <div className="icon">
                <img
                  src="../../images/quotation-mark-dark.svg"
                  alt="vinay mehandi artist"
                />
              </div>
              <p className="review">
                I would Already recommended him for my upcoming relative's
                weddings. I am very happy from his walk.
              </p>
            </div>
            <div className="client-wrap">
              <img src="../../images/client_2.png" alt="vinay mehandi artist" />
              <h5>Shivani</h5>
            </div>
          </div>
        </div>
      </MainContainer>
    </HomeStyles>
  );
};

export default Home;
