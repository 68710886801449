import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ContactStyles } from "../styles/Contact.style";
import { send } from "emailjs-com";
import { Helmet } from "react-helmet";
import LoaderSpinner from "../components/LoaderSpinner";

const initialState = {
  sender_name: "",
  email: "",
  phone: "",
  message: "",
  nameError: "",
  emailError: "",
  phoneError: "",
  messageError: "",
  successMessage: "",
  animateIcon: false,
  sendButton: false,
};

class Contact extends Component {
  state = initialState;

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = (e) => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let messageError = "";

    if (!this.state.sender_name) {
      nameError = "Name cann't be blank.";
    }
    if (!this.state.email) {
      emailError = "Please enter your email address.";
    } else if (!this.state.email.includes("@")) {
      emailError = "Please enter a valid email address.";
    }
    if (!this.state.phone) {
      phoneError = "Please enter your phone number.";
    } else if (this.state.phone.toString().length <= 6) {
      phoneError = "This field is too short.";
    }
    if (!this.state.message) {
      messageError = "Please write your message.";
    }
    if (emailError || nameError || phoneError || messageError) {
      setTimeout(() => {
        this.setState({ emailError, nameError, phoneError, messageError });
      }, 2000);
      return false;
    }
    return true;
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ animateIcon: true });

    this.setState({ sendButton: true });

    setTimeout(() => {
      this.setState({ sendButton: false });
    }, 2000);

    setTimeout(() => {
      this.setState({ animateIcon: false });
    }, 2000);

    const isValid = this.validate();

    const { sender_name, email, phone, message } = this.state;
    if (isValid) {
      setTimeout(() => {
        this.setState(initialState);
      }, 2000);

      setTimeout(() => {
        this.setState({
          successMessage: "Your message has sent successfully.",
        });
      }, 2000);

      send(
        "service_kvmrc1i",
        "template_rkdldm5",
        { sender_name, email, phone, message },
        "dQ5PnT5t_7yS3ACyZ"
      )
        .then((response) => {
          console.log(
            "Message sent successfully",
            response.status,
            response.text
          );
        })
        .catch((err) => {
          console.log("Failed", err);
        });
    }
  };

  render() {
    return (
      <ContactStyles>
        <Helmet>
          <title>Mehandi Studio : Contact Us</title>
          <link
            rel="canonical"
            href="https://www.mehandistudio.com/contact"
          />
          <meta
            name="descriptions"
            content="Mehandi Studio, Best Mehandi Studio in Delhi, Best Henna Artists, Best Mehandi Designers, Best Henna Designers"
          />
        </Helmet>
        <div className="row gx-5">
          {/* <div className='left-container col-lg-5 col-md-6 col-11'>
                        <img src='../../images/contact-vinay.svg' alt='vinay arts contact' />
                    </div> */}
          <div className="left-container col-lg-5 col-md-6 col-11">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112166.25017281497!2d77.10945334335939!3d28.5338484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce178c44ff4d7%3A0x3dbb3ada48a22cbc!2sVinay%20Mehandi%20Artist!5e0!3m2!1sen!2sin!4v1723809922463!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowfullscreen="true"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="right-container col-lg-4 col-md-5 col-11">
            <form onSubmit={this.onSubmit}>
              <div className="enquiry-elem-wrap">
                <label className="label">Name :</label>
                <input
                  className="input-field"
                  name="sender_name"
                  value={this.state.sender_name}
                  onChange={this.handleChange}
                  maxLength="25"
                  onKeyPress={(e) => {
                    if (!/[A-Z, a-z]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <div style={{ fontSize: ".8rem", color: "red" }}>
                  {this.state.nameError}
                </div>
              </div>
              <div className="enquiry-elem-wrap">
                <label className="label">Number :</label>
                <input
                  className="input-field"
                  name="phone"
                  maxLength="15"
                  value={this.state.phone}
                  onChange={this.handleChange}
                  defaultValue={this.state.phone}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <div style={{ fontSize: ".8rem", color: "red" }}>
                  {this.state.phoneError}
                </div>
              </div>
              <div className="enquiry-elem-wrap">
                <label className="label">Email :</label>
                <input
                  className="input-field"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <div style={{ fontSize: ".8rem", color: "red" }}>
                  {this.state.emailError}
                </div>
              </div>
              <div className="enquiry-elem-wrap">
                <label className="label">Message :</label>
                <textarea
                  className="input-field message-field"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  maxLength="400"
                />
                <div style={{ fontSize: ".8rem", color: "red" }}>
                  {this.state.messageError}
                </div>
              </div>
              <div className="enquiry-elem-wrap button-wrap">
                <button type="submit" onClick={this.onSubmit}>
                  {this.state.sendButton === false ? "Send" : "Sending..."}
                </button>
                {this.state.animateIcon && <LoaderSpinner />}
              </div>
              <div
                style={{
                  marginTop: "2rem",
                  fontSize: "1rem",
                  color: "#3399FF",
                }}
              >
                {this.state.successMessage}
              </div>
            </form>
          </div>
        </div>
      </ContactStyles>
    );
  }
}

export default Contact;
