import React from 'react';
import { Link } from 'react-router-dom';
import { FooterStyles } from '../styles/Footer.style';
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { SiMinutemailer, SiInstagram } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { RiYoutubeLine, RiTwitterLine } from "react-icons/ri";

const Footer = () => {
    return (
        <FooterStyles>
            <div className='footer-inner-container'>
                <div className='top-container row'>
                    <div className='menu-wrap logo-wrap col-md-3'>
                        <div className='logo-icon-wrap'>
                            <img src='../../images/vinay-arts.png' alt='vinay arts' />
                        </div>
                        <h4>Vinay Arts</h4>
                        <div className='social-media-icon-wrap'>
                            <a 
                                className='social-media-icon' 
                                href='https://www.facebook.com/profile.php?id=100064580838309'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <FaFacebookF />
                            </a>
                            <a 
                                className='social-media-icon instagram' 
                                href='https://www.instagram.com/vinaymehandi009/'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <SiInstagram />
                            </a>
                            <a 
                                className='social-media-icon twitter' 
                                href='https://twitter.com/MehndiVinay?t=Vc6rFWCpBS8WvnGlBE-mXg&s=09'
                                target="_blank" 
                                rel="noopener noreferrer" 
                            >
                                <RiTwitterLine />
                            </a>
                            <a 
                                className='social-media-icon youtube' 
                                href='https://youtube.com/channel/UCtUztcEN5P8bFmDnOuudm2A'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <RiYoutubeLine />
                            </a>
                        </div>
                    </div>
                    <div className='menu-wrap col-md-4'>
                        <div className='menu-title'>
                            <span>Contact</span>
                            <hr />
                        </div>
                        <ul>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    <FaMapMarkerAlt className='fa-icon'/>
                                    B-9, Alaknanda Shopping Complex, Delhi
                                </div>
                            </li>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    <FaPhoneAlt className='fa-icon'/>
                                    9958273689
                                </div>
                            </li>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    <SiMinutemailer className='fa-icon'/>
                                    vnymehndi@gmail.com
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='menu-wrap col-md-3'>
                        <div className='menu-title'>
                            <span>Experties</span>
                            <hr />
                        </div>
                        <ul>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    Bridal Mehandi Design
                                </div>
                            </li>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    Rajasthani Mehandi Design
                                </div>
                            </li>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    Glitter Mehandi Design
                                </div>
                            </li>
                            <li className='menu-links'>
                                <div 
                                    className='menu-link-item'
                                >
                                    Madhubani Mehandi Design
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='menu-wrap col-md-2'>
                        <div className='menu-title'>
                            <span>Gallery</span>
                            <hr />
                        </div>
                        <Link to='/gallery' 
                            className='thumbnail-wrap row g-2'
                        >
                            <div className='image-thumbnail col-4'>
                                <img src='../../images/bridal/vinay-arts-bridal9.jpg' alt='' />
                            </div>
                            <div className='image-thumbnail col-4'>
                                <img src='../../images/bridal/vinay-arts-bridal10.jpg' alt='' />
                            </div>
                            <div className='image-thumbnail col-4'>
                                <img src='../../images/bridal/vinay-arts-bridal11.jpg' alt='' />
                            </div>
                            <div className='image-thumbnail col-4'>
                                <img src='../../images/bridal/vinay-arts-bridal12.jpg' alt='' />
                            </div>
                            <div className='image-thumbnail col-4'>
                                <img src='../../images/bridal/vinay-arts-bridal13.jpg' alt='' />
                            </div>
                            <div className='image-thumbnail col-4'>
                                <img src='../../images/bridal/vinay-arts-bridal14.jpg' alt='' />
                            </div>
                        </Link>
                    </div>
                </div>
                <hr />
                <div className='bottom-wrap'>
                    <div className='left-content'>
                        Copyright 2024 © Vinay Arts. All rights reserved.
                    </div>
                    <div className='right-content'>
                        Designed & developed by&nbsp;
                        <a href='https://www.artographertacts.com/'>ArtographerTacts</a>.
                    </div>
                </div>
            </div>
            
        </FooterStyles>
    );
}

export default Footer;
