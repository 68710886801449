import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import GlobalStyle from './GlobalStyles';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import WhatsApp from './components/WhatsApp';
import Error404 from './pages/Error404';
import ReactGA from 'react-ga';
import Airport from "./pages/Airport";
import Videos from "./pages/Videos";



function App() {

  // const location = useLocation();

  // useEffect(() => {
  //   ReactGA.initialize('UA-243355555-1', {
  //     gaOptions: {
  //       cookieFlags: 'SameSite=None;Secure'
  //     }
  //   });
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  return (
    <div className="App">
      <Helmet>
        <title>Mehandi Studio : Best Mehandi Artists in Delhi</title>
        <link rel="canonical" href="https://www.mehandistudio.com" />
        <meta
          name="descriptions"
          content="Mehandi Studio, Best Mehandi Studio in Delhi, Best Henna Artists, Best Mehandi Designers, Best Henna Designers"
        />
        <meta
          name="keywords"
          content="mehandi, mehandi studio, mehandi artist, henna artist, bridal mehandi, bridal henna"
        />
      </Helmet>
      <GlobalStyle />
      <Navbar />
      <WhatsApp />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/contact' exact element={<Contact />} />
        <Route path='/gallery' exact element={<Gallery />} />
        <Route path='/mehndi-on-airport' exact element={<Airport />} />
        <Route path='/video-gallery' exact element={<Videos />} />
        <Route path='/error-404' exact element={<Error404 />} />
        <Route path="/*" element={<Navigate to='/error-404' />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
