import React from 'react';
import '../styles/LoaderSpinner.css';

const LoaderSpinner = () => {
    return (
        <div>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    );
}

export default LoaderSpinner;