const VideoData = [
    {
        id: 1,
        videoId: 'cVOrxeT8MXk',
        caption: 'Delighted to create beautiful henna designs for our UK clients and their daughter!'
    },{
        id: 2,
        videoId: '-V_HMjekTPQ',
        caption: 'Beautiful Krishna design applied for our London client. Watch the art unfold!'
    },
    {
        id: 3,
        videoId: '3ZmWgzOaE9g',
        caption: 'Welcoming our international client from Italy—beautiful mehndi art at our studio!'
    },
    {
        id: 4,
        videoId: 'sYrnla4ec2U',
        caption: 'International traveler’s special henna experience at Indira Gandhi Airport—watch her joy!'
    }
];


export default VideoData;