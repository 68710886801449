import styled from "styled-components";

export const FooterStyles = styled.div`
    background-color: var(--dark-color);
    color: var(--light-color);

    @media screen and (max-width: 767px) {
        text-align: center;
    }


    .footer-inner-container {
        padding: 50px 70px 15px 70px;

        @media screen and (max-width: 991px) {
            padding: 50px 30px 15px 30px;
        }

        @media screen and (max-width: 480px) {
            padding: 50px 15px 15px 15px;
        }

        .top-container {

            .menu-wrap {
                
                @media screen and (max-width: 767px) {
                    margin-bottom: 2rem;
                }


                .menu-title {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.3rem;
                    margin-bottom: 1rem;

                    @media screen and (max-width: 767px) {
                        padding: 0 4rem;
                        margin-bottom: -.2rem;
                    }

                    span {

                        @media screen and (max-width: 767px) {
                            margin-bottom: -.4rem;
                            display: block;
                        }
                    }
                    
                }

                ul{
                    margin: 0;
                    padding: 0;

                    .menu-links {
                        margin-bottom: .5rem;
                        list-style: none;
                        font-size: .85rem;
                        font-weight: 300;

                        .menu-link-item {
                            color: #CCCCCC;

                            .fa-icon {
                                margin-right: .8rem;
                            }
                        }
                    }
                }

                .thumbnail-wrap {
                    justify-content: center;

                    .image-thumbnail {
                        max-height: 60px;
                        max-width: 60px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .logo-wrap {
                text-align: center;
                margin-top: -1rem;

                .logo-icon-wrap {

                    img {
                        width: 40%;
                        height: 40%;
                        object-fit: contain;
                    }
                }

                h4 {
                    margin-top: .5rem;
                    font-size: 1.7rem;

                    @media screen and (max-width: 480px) {
                        font-size: 1.4rem;
                    }
                }

                .social-media-icon-wrap {
                    margin-top: .2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .social-media-icon{
                        font-size: 1rem;
                        color: var(--light-color);
                        margin-right: 1rem;
                        cursor: pointer;

                        @media screen and (max-width: 767px) {
                            font-size: 1.5rem;
                            margin-right: 2rem;
                        }

                        @media screen and (max-width: 480px) {
                            font-size: 1.1rem;
                            margin-right: 1.2rem;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &:hover {
                            color: var(--primary-color);
                        }
                    }

                    .twitter {
                        font-size: 1.4rem;
                        margin-right: .8rem;

                        @media screen and (max-width: 767px) {
                            font-size: 2rem;
                            margin-right: 1.6rem;
                        }

                        @media screen and (max-width: 480px) {
                            font-size: 1.5rem;
                            margin-right: 1rem;
                        }
                    }

                    .youtube {
                        font-size: 1.4rem;

                        @media screen and (max-width: 767px) {
                            font-size: 2rem;
                        }

                        @media screen and (max-width: 480px) {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }

        .bottom-wrap {
            font-size: .8rem;
            font-weight: 300;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #B3B3B3;

            @media screen and (max-width: 767px) {
                flex-direction: column;
            }

            @media screen and (max-width: 480px) {
                font-size: .7rem;
            }

            a {
                text-decoration: none;
                color: #B3B3B3;
                font-weight: 500;

                &:hover {
                    color: var(--primary-color);
                }
            }

            .left-content {

                @media screen and (max-width: 767px) {
                    margin-bottom: .2rem;
                }

            }
        }
    }
`;