import styled from "styled-components";


export const NavbarStyle = styled.nav`
    padding: 1.5rem 0;

    .navbar-inner-container {
        text-transform: uppercase;
        text-align: center;

        .nav-top-wrap {
            display: flex;
            justify-content: center;

            @media screen and (max-width: 767px) {
                flex-direction: column-reverse;
                align-items: center;
            }


            .nav-links-wrap {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.4rem;
                margin-top: 1.5rem;
                margin-left: .6rem;
                display: flex;

                @media screen and (max-width: 992px) {
                    margin-top: .9rem;
                    font-size: .9rem;
                    margin-left: .5rem;
                }

                @media screen and (max-width: 767px) {
                    margin-bottom: 1rem;
                    margin-top: .5rem;
                    margin-left: 0;
                }

                @media screen and (max-width: 480px) {
                    margin-bottom: .3rem;
                    font-size: .9rem;
                }


                .nav-link {
                    text-decoration: none;
                    color: var(--dark-color);
                    margin-left: 1rem;
                    margin-right: 1rem;
                    cursor: pointer;

                    @media screen and (max-width: 992px) {
                        margin-left: .5rem;
                        margin-right: .5rem;
                    }

                    @media screen and (max-width: 767px) {
                        margin-left: 1rem;
                        margin-right: 1rem;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    @media screen and (max-width: 480px) {
                        margin-left: .5rem;
                        margin-right: .5rem;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .active {
                    color: var(--primary-color);
                }

                .link-divider {
                    color: gray;
                }
            }
        }

        .nav-bold-text {
            font-size: 2.5rem;
            line-height: 3.2rem;
            font-weight: 700;

            @media screen and (max-width: 992px) {
                font-size: 2rem;
                line-height: 2.5rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.5rem;
                line-height: 2rem;
                margin: 0;

            }

            @media screen and (max-width: 480px) {
                font-size: 1.2rem;
                line-height: 1.6rem;
            }


            span {
                color: var(--primary-color);
            }
        }
    }

    .absolute-container {
        text-align: right;
        margin-right: 1rem;

        .airport-link {
            cursor: pointer;
        }

        .airport-link-img {
            cursor: pointer;

            @media screen and (max-width: 767px) {
                width: 70%;
                height: auto;

            }
        }

        
    }
`;