import React from 'react';
import { RiWhatsappFill } from "react-icons/ri";
import styled from 'styled-components';

export const Style = styled.div`
    text-align: center;
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 60px;
    height: 60px;
    z-index: 99999;
    background-color: #25D366;
    border-radius: 50px;
    transition: all ease .3s;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .2);

    @media screen and (max-width: 480px) {
        width: 50px;
        height: 50px;
    }

    &:hover {
        scale: 1.1;
    }

    .whatsapp_float {
        font-size: 40px;
        color: #fff;
        margin-top: -3px;
        display: block;

        @media screen and (max-width: 480px) {
            font-size: 35px;
            margin-top: -4px;
        }
`;

const WhatsApp = () => {
    return (
        <Style>
            <a
                href="https://wa.me/919958273689"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <RiWhatsappFill />
            </a>
        </Style>
    );
}

export default WhatsApp;
