import React from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButtonStyles } from '../GlobalStyles';

const PrimaryButton = (props) => {
    return (
        <PrimaryButtonStyles>
            <Link to={props.link}
                className='primary-btn'
            >
                {props.title}
            </Link>
        </PrimaryButtonStyles>
    );
}

export default PrimaryButton;
