import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MainContainer } from '../GlobalStyles';
import { AiOutlineCaretRight } from "react-icons/ai";

const Styles = styled.div`

    .image-wrap {
        margin-bottom: 1rem;
        margin-top: -3rem;

        img {
            margin-left: 5rem;
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media screen and (max-width: 991px) {
                margin-left: 4rem;
            }

            @media screen and (max-width: 767px) {
                margin-left: 2rem;
            }
        }
    }

    h1{
        font-size: 2rem;

        @media screen and (max-width: 480px) {
            font-size: 1.2rem;
        }
    }

    h5 {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;

        @media screen and (max-width: 480px) {
            font-size: .8rem;
        }
    }

    .home-link {
        font-size: 1.2rem;
        text-decoration: none;
        color: var(--paragraph-color);

        &:hover {
            color: var(--primary-color);
            cursor: pointer;
        }

        @media screen and (max-width: 480px) {
            font-size: .9rem;
        }

        .icon {
            font-size: 1.5rem;
            margin-right: .5rem;

            @media screen and (max-width: 480px) {
                font-size: 1.2rem;
                margin-right: .1rem;
            }
        }
    }
`;

const Error404 = () => {
    return (
        <Styles>
            <MainContainer>
                <div className='row justify-content-center'>
                    <div className='image-wrap col-6 justify-content-center'>
                        <img src='../../images/error-404.png' alt='error-404' />
                    </div>
                </div>
                <h1>SORRY, THE PAGE NOT FOUND.</h1>
                <h5>
                    The link you followed probably broken
                    <br />
                    or the page has been removed.
                </h5>
                <Link to='/'
                    className='home-link'
                >
                    <AiOutlineCaretRight className='icon' />
                    Return to Homepage
                </Link>
            </MainContainer>
        </Styles>
    );
}

export default Error404;
