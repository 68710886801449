import React, { useState } from "react";
import styled from "styled-components";
import VideoData from "../components/VideoData";
import { SectionSubtitle, SectionTitle } from "../GlobalStyles";
import PrimaryButton from "./Buttons";

const Styles = styled.div`
  .video-gallery-container {
    .inner-container {
      .iframe-container {
        display: flex;
        overflow: scroll;
        padding: 3rem 0 2rem 0;
        background-color: rgba(0, 0, 0, 1);

        .iframe-wrap {
          margin: 0 1rem;

          iframe {
            width: 20rem;

            @media screen and (max-width: 991px) {
              width: 15rem;
            }

            @media screen and (max-width: 480px) {
              width: 12rem;
            }
          }

          .iframe-title {
            color: #fff;
            text-align: left;
            font-size: 0.8rem;
            line-height: 1.1rem;
            font-weight: 300;
          }
        }
      }

      .btn-container {
        margin-top: 3rem;
      }
    }
  }
`;

const VideoScroller = () => {
  const [items, setItems] = useState(VideoData);

  return (
    <Styles>
      <div className="video-gallery-container">
        <div className="inner-container">
          <SectionSubtitle>Our Video Gallery</SectionSubtitle>
          <SectionTitle>Watch us</SectionTitle>
          <div className="iframe-container">
            {items.map((slide, id) => {
              return (
                <div className="iframe-wrap" key={id}>
                  <iframe
                    className="video"
                    title="Youtube player"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/${slide.videoId}?autoplay=0`}
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                  />
                  <div className="iframe-title">{slide.caption}</div>
                </div>
              );
            })}
          </div>
          <div className="btn-container">
            <PrimaryButton title="Video Gallary" link="/video-gallery" />
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default VideoScroller;
